import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4923dff3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=4923dff3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4923dff3",
  null
  
)

export default component.exports
import {QScrollArea,QSpinnerIos,QVirtualScroll,Intersection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollArea,QSpinnerIos,QVirtualScroll})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Intersection})
























































import { UnreadData } from '@/components/ChatsBar/types'
import { chatsBarStore } from '@/store'
import { Chat, JID } from '@tada-team/tdproto-ts'
import type { QScrollArea, QVirtualScroll } from 'quasar'
import { Component, Watch, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Item: () => import('@/components/ChatsBar/List/Item/index.vue'),
    UnreadNotifier:
      () => import('@/components/ChatsBar/List/UnreadNotifier/index.vue'),
  },
})
export default class List extends Vue {
  @Prop({ type: Array, default: [] }) private readonly chats!: Chat[]
  @Prop({ type: Boolean }) private readonly isLoading?: boolean
  @Prop({ type: Boolean }) private readonly isShortView?: boolean
  @Prop({ type: Boolean }) private readonly showUnreadNotifier?: boolean
  @Prop({ type: String }) private readonly selectedChat?: JID

  @Ref() private readonly scrollArea!: QScrollArea
  @Ref() private readonly virtualScroll!: QVirtualScroll

  private visibleIndexes: number[] = []

  resetScroll () {
    this.scrollArea.setScrollPosition(0)
  }

  @Watch('selectedChat')
  private onChangeSelectedChat () {
    if (!this.selectedChat) return

    const index = this.chats.findIndex(chat => chat.jid === this.selectedChat)
    if (index === -1) return

    this.virtualScroll.scrollTo(index)
  }

  private get topChats () {
    return this.chats.slice(0, this.visibleIndexes[0])
  }

  private get bottomChats () {
    return this.chats.slice(this.visibleIndexes[this.visibleIndexes.length - 1])
  }

  private get unreadData (): UnreadData {
    const { bottomChats, topChats } = this
    return {
      top: {
        count: topChats.reduce((v, item) => v + (item.numUnread ?? 0), 0),
        noticed: topChats.some(item => !!item.numUnreadNotices),
      },
      bottom: {
        count: bottomChats.reduce((v, item) => v + (item.numUnread ?? 0), 0),
        noticed: bottomChats.some(item => !!item.numUnreadNotices),
      },
    }
  }

  private get itemSize () {
    return this.isShortView ? 34 : 54
  }

  private get rosterIsReady () {
    return chatsBarStore.state.rosterIsReady
  }

  private onIntersection (entry: IntersectionObserverEntry, index: number) {
    const idx = this.visibleIndexes.findIndex(item => item === index)
    if (idx !== -1) this.visibleIndexes.splice(idx, 1)
    if (entry.isIntersecting) this.visibleIndexes.push(index)
    this.visibleIndexes.sort((a, b) => a - b)
  }

  private onUpBtnClick () {
    const index = this.topChats.findIndex(item => !!item.numUnread)
    if (index === -1) this.scrollArea.setScrollPosition(0, 300)
    else this.scrollArea.setScrollPosition(index * this.itemSize, 300)
  }

  private onDownBtnClick () {
    let index = this.bottomChats.findIndex(item => !!item.numUnread)
    if (index === -1) return
    index += this.visibleIndexes.length - 1
    this.scrollArea.setScrollPosition(index * this.itemSize, 300)
  }

  private readonly thumbStyle: Partial<CSSStyleDeclaration> = {
    right: '4px',
    borderRadius: '4px',
    backgroundColor: '#505050',
    width: '4px',
    opacity: '1',
  }
}
